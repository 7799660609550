import React from 'react'
import NavBar from '../components/global/NavBar'
import Footer from '../components/global/Footer'

export default function privacypolicy() {
    return (
        <>
            <NavBar />
            <div className='container py-[40px]'>
                <div className='px-[10px] md:px-[300px] pb-10'>
                    <h1 className='my-6 text-center text-[18px] font-semibold text-gray-800'>GENERAL</h1>
                    <p className='text-center text-[14px] font-normal text-gray-700'>Your privacy is important to us. This privacy policy is intended to give you confidence in the privacy and security of the personal information we obtain from you whether you are using products such as the SimpleCV app or others (the “Products”), uploading information to SimpleCV and our products’ websites (the “Websites”), such as https://mega.persona.co, downloading our applications, or are merely visiting our Websites. However, please note that we are not responsible for any use of your personal information you provide to third-party applications or websites that may be accessed via the Products or Websites. We recommend that you review the privacy policy of any third-party applications or websites that you use.</p>
                </div>
                <div className='px-[10px] md:px-[300px] pb-10'>
                    <h1 className='my-6 text-center text-[18px] font-semibold text-gray-800'>COLLECTION OF PERSONAL INFORMATION
                    </h1>
                    <p className='text-center text-[14px] font-normal text-gray-700'>When you use our Products or Websites, you may be asked for personally identifiable information such as your name, address, date of birth, email address, telephone number.By giving us such information, you will need to consent to our using it in the manner described in this policy.You may withdraw your consent at anytime by emailing us at megamobile@gmail.com. We will return or destroy your personal information within five days of receipt of your withdrawal of consent. Cookie information, pages you have requested and your IP address may also be recorded by us and/or third parties from your browser as set out in this policy.</p>
                </div>
                <div className='px-[10px] md:px-[300px] pb-10'>
                    <h1 className='my-6 text-center text-[18px] font-semibold text-gray-800'>USE OF PERFORMANCE DATA
                    </h1>
                    <p className='text-center text-[14px] font-normal text-gray-700'>examples will use performance data, including performance statistics, bug fixes and other similar data (“Performance Data”) uploaded to our Websites or acquired through our Products. LUNA may use such Performance Data in any way, including, but not limited to, using, editing, altering, reproducing, publishing and/or distributing the Performance Data for any purpose, commercial or otherwise, provided that such Performance Data is stripped of all personally identifiable information.</p>
                </div>
                <div className='px-[10px] md:px-[300px] pb-10'>
                    <h1 className='my-6 text-center text-[18px] font-semibold text-gray-800'>USE OF PERSONAL INFORMATION
                    </h1>
                    <p className='text-center text-[14px] font-normal text-gray-700'>We use personal information to allow us to process your service requests, provide access to privileged areas of the Websites, send out newsletters, personalize your visit to our Websites, personalize your use of our Products, and enable us to improve the products and services we offer. We may occasionally carry out market research and send you details of services and offers that we think may be of interest to you. If you do not wish to receive such information, please e-mail megamobile@gmail.com or, alternatively, when we send you an e-mail, it will contain a provision for you to opt out of receiving any further information from us.
                    </p>
                </div>
                <div className='px-[10px] md:px-[300px] pb-10'>
                    <h1 className='my-6 text-center text-[18px] font-semibold text-gray-800'>DISCLOSURE OF PERSONAL INFORMATION
                    </h1>
                    <p className='text-center text-[14px] font-normal text-gray-700'>We may provide information about you to our employees and agents in order to administer any accounts, products and services provided to you by 5 examples.We will not disclose your personal information to any third party unless you have consented to such disclosure or where we are required to do so by law. Should you breach our terms and conditions or terms of use or if we are under a duty to disclose or share your personal data in order to comply with any legal obligation, we may disclose your information to a relevant authority. This may include exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction. Any disclosure of personal information will be strictly controlled and made fully in accordance with current U.S. law.
                    </p>
                </div>
                <div className='px-[10px] md:px-[300px] pb-10'>
                    <h1 className='my-6 text-center text-[18px] font-semibold text-gray-800'>COOKIES
                    </h1>
                    <p className='text-center text-[14px] font-normal text-gray-700'>Cookies are small data files that a website you visit may save on your computer or handheld device that usually includes an anonymous unique identifier. Our Websites and those of our Products may use cookies for user authentication, keeping track of your preferences, promotional campaigns, tracking our audience size and traffic patterns, and in certain other cases. We may include small graphic images in our email messages and newsletters to determine whether the messages were opened and the links were viewed. Third-party advertisers may also create and access cookies, which will be subject to their privacy policies – we accept no responsibility or liability for the use of such third parties’ cookies. If you do not wish cookies to be placed on your PC or handheld device, then they can be disabled in your web browser. The option to do so is normally found in your browser’s “security settings” section. Please note that permanently disabling cookies in your browser may hinder your use of our Websites as well as other websites and interactive services.
                    </p>
                </div>
                <div className='px-[10px] md:px-[300px] pb-10'>
                    <h1 className='my-6 text-center text-[18px] font-semibold text-gray-800'>FACE DATA USAGE
                    </h1>
                </div>
                <div className='px-[10px] md:px-[300px] pb-10'>
                    <h1 className='my-6 text-center text-[18px] font-semibold text-gray-800'>SECURITY</h1>
                    <p className='text-center text-[14px] font-normal text-gray-700'>All security on our Websites is treated seriously. Where applicable, we undertake security steps, including use of SSL technology, on our back-end systems that store customer account information and to protect data transmissions. However, this is not a guarantee that such data transmissions cannot be accessed, altered or deleted due to firewall or other security software failures. If you have any further concerns about security, please email our Customer Service team at megamobile@gmail.com
                    </p>
                </div>
                <div className='px-[10px] md:px-[300px] pb-10'>
                    <h1 className='my-6 text-center text-[18px] font-semibold text-gray-800'>POLICY CHANGES
                    </h1>
                    <p className='text-center text-[14px] font-normal text-gray-700'>Changes in this policy will be posted on our Websites. You are advised to check our Websites regularly to view our most recent privacy policy.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    )
}
